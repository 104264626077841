@charset "UTF-8";

.MuiInput-underline:before {
    content: none!important;
}
.MuiInput-underline:after {
    content: none!important;
}
.MuiAppBar-root .MuiSelect-icon {
    color: white;
}
a {
     color: #b21058;
     text-decoration: none; }

a:hover,
a:focus {
    color: #ed2c83;
    text-decoration: none; }
